@import 'overrides';

@import '../node_modules/bootstrap/scss/bootstrap';

@import 'grid';
@import 'buttons';
@import 'links';

@import 'header';
@import 'page-title';

@import 'tabs';
@import 'projects';

@import 'resume';

@import 'social-list';
@import 'spinner';

@import 'cta';
@import 'footer';

b, strong {
    color: $blue;
}

h3 {
    margin-bottom: 2.5rem; // 40px

    font-size: 2.25rem; // 36px
    font-weight: normal;
}

h5 {
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: bold;
}

.pswp__img--placeholder--blank {
    display: none !important;
}
