.btn {
    font-weight: 500;
}

.btn-secondary {
    @include button-variant($blue-muted, $blue-muted);

    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
        color: $blue !important;
    }
}

.btn-wide {
    padding-left: 3rem;
    padding-right: 3rem;
}

.btn-rounded {
    border-radius: 100px;
}
