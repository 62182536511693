@mixin has-links {
    a {
        border-bottom: 2px solid #ddd;
        font-weight: bold;

        &:hover {
            border-bottom-color: $link-hover-color;
            text-decoration: none;
        }
    }
}
