.two-columns {
    @extend .row;

    &--centered {
        align-items: center;
    }

    &__col {
        @extend .col-lg-6;

        &:last-child {
            @include media-breakpoint-down('md') {
                margin-top: 40px;
            }
        }
    }
}
