// Okay, this grid was like a trial and error
// I'm not sure whether that's working perfectly
// on all major browsers ( ͡° ʖ̯ ͡°)

.projects {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    // grid-auto-rows: 1fr;
    grid-gap: 20px;

    transition: opacity .1s;

    @include media-breakpoint-down('lg') {
        grid-template-columns: repeat(4, 1fr);
    }

    @include media-breakpoint-down('md') {
        grid-template-columns: repeat(3, 1fr);
    }

    @include media-breakpoint-down('sm') {
        grid-template-columns: repeat(2, 1fr);
    }

    &__item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1.25rem; // 20px

        @include media-breakpoint-down('xs') {
            grid-column: span 2;
        }

        &--wide {
            grid-column: span 2;
        }
    }

    &__title {
        margin-bottom: 1.25rem; // 20px
        font-size: 1.5rem; // 24px;
    }

    &__description {
        @include has-links;

        margin: 0;
    }

    &__image,
    &__pager {
        width: 100%;
        border-radius: 3px;
        object-fit: cover;

        height: 206px;

        @include media-breakpoint-down('lg') {
            height: 217.5px;
        }
    
        @include media-breakpoint-down('md') {
            height: 216.66px;
        }
    
        @include media-breakpoint-down('sm') {
            height: 245px;
        }
    
        @include media-breakpoint-down('xs') {
            height: calc((100vw - 50px) / 2);
        }
    }

    &__pager {
        @extend .btn, .btn-secondary;

        display: flex;
        align-items: center;
        justify-content: center;
        
        background: $blue-muted;
        font-size: 1.5rem;
        font-weight: 500;
    }
}
