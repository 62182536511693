$font-family-sans-serif: Roboto, sans-serif;

$blue: #305CF8;
$blue-muted: #F1F4FE;
$body-color: #000;

$alert-border-width: 0;

$input-btn-border-width: 0;

$input-btn-padding-y: 0.875rem; // 14px
$input-btn-padding-x: 1.5rem; // 24px

$input-btn-padding-x-lg: 44px; // 44px
$input-btn-padding-y-lg: 20px; // 20px
$input-btn-font-size-lg: 1rem; // 16px

$input-bg: $blue-muted;
