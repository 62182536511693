.spinner-container {
    position: relative;
}

.spinner {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    margin: auto;

    width: 20px;
    height: 20px;
    animation: rotation 1.35s linear infinite;

    &__circle {
        stroke: $white;

        stroke-dasharray: 180;
        stroke-dashoffset: 0;
        transform-origin: center;
        animation: turn 1.35s ease-in-out infinite;
    }

    @keyframes rotation {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(270deg); }
    }
    
    @keyframes turn {
        0% { stroke-dashoffset: 180; }
        50% {
            stroke-dashoffset: 45;
            transform: rotate(135deg);
        }
        100% {
            stroke-dashoffset: 180;
            transform: rotate(450deg);
        }
    }
}
