.footer {
    margin-top: 8.75rem; // 140px
    padding: 2.5rem 0; // 40px 0

    color: $gray-700;
    background: $blue-muted;
    font-size: 0.875rem; // 14px

    &__container {
        @extend .container;

        display: grid;

        grid-template-areas: "title social" "copyright links";
        grid-row-gap: 1.25rem; // 20px
        justify-content: space-between;

        @include media-breakpoint-down('sm') {
            grid-template-areas: "title" "social" "links" "copyright";
            justify-content: center;
            justify-items: center;
        }
    }

    &__title {
        grid-area: title;

        margin: 0;

        font-size: 1.5rem; // 24px
        font-weight: 300;
    }

    &__copyright {
        grid-area: copyright;

        margin: 0;


    }

    &__social, &__links-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        margin: 0;
        padding: 0;
        list-style: none;

        @include media-breakpoint-up('md') {
            justify-self: end;
        }
    }

    &__social {
        grid-area: social;
    }

    &__social-item {
        margin-left: 1rem; // 16px
    }

    &__social-link {
        //
    }

    &__social-icon {
        height: 1.5rem; // 24px
    }

    &__links {
        grid-area: links;

        font-weight: 500;
    }

    &__links-list {
        //
    }

    &__links-item {
        margin-left: 0.875rem; // 7px

        @include media-breakpoint-down('sm') {
            margin: 0 0.4325rem;
        }
    }

    &__link {
        //
    }

    &__debug, &__debug:hover {
        color: transparentize($body-color, 0.7);
    }
}
