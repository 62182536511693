.social-list {
    display: flex;
    align-items: center;
    justify-content: center;

    &__list {
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
    
        margin: 0;
        padding: 0;
    
        list-style: none;

        @include media-breakpoint-down('md') {
            flex-flow: row wrap;
        }
    }

    &__item {
        margin: 0.625rem; // 10px
    }

    &__link {
        display: flex;
        align-items: center;

        font-weight: 500;

        &:hover {
            text-decoration: none;
            color: $blue;
        }
    }

    &__link:hover &__icon {
        background: darken($blue-muted, 7.5%);
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3rem; // 48px
        height: 3rem; // 48px

        margin-right: 1.25rem; // 20px
        padding: 0.75rem 0; // 12px 0

        border-radius: $btn-border-radius;
        background: $blue-muted;
        transition: $btn-transition;

        @include media-breakpoint-down('sm') {
            margin-right: 0;
        }
    }

    &__name {
        @include media-breakpoint-down('sm') {
            display: none;
        }
    }
}
