$serifFont: Merriweather, serif;
$sansSerifFont: 'Open Sans', sans-serif;

$accentColor: #2079c7;
$fontColor: #000;

$smallWidth: 780px;

@mixin print {
    @media print {
        @content;
    }
}

@mixin notPrint {
    @media not print {
        @content;
    }
}

@mixin mobile {
    @media screen and (max-width: map-get($grid-breakpoints, md)) {
        @content;
    }
}

@mixin notMobile {
    @media print, (min-width: #{map-get($grid-breakpoints, md) + 1px}) {
        @content;
    }
}

.resume-brief .resume-detailed {
    display: none;
}

.resume {
    @include notPrint {
        margin: 0 auto;
    
        padding: 75px 60px 30px;
        max-width: 210mm;
        min-height: 297mm;

        background: #fff;
        box-shadow:
            0 3px 6px rgba(0, 0, 0, .1),
            0 0 3px rgba(0, 0, 0, .1);
    }

    @include mobile {
        display: flex;
        flex-direction: column;

        margin: 60px 0 0;
    }

    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: auto 1fr;

    color: $fontColor;
    font: 12px/1.5 $serifFont;

    &--header {}
    &--contact {}
    &--main {}
    &--side {}

    &--footer {
        grid-column: span 2;
        margin-top: 30px;

        font-size: 11px;
        text-align: center;
        font-style: italic;
    }

    a, b {
        color: $fontColor;
    }

    a {
        text-decoration: underline;

        @include print {
            text-decoration: none;
        }
    }
}

.header-color {}

.resume-name {
    margin: 0 0 8px;
    font-size: 48px;
}

.resume-social-media {
    display: flex;
    margin: 0;
    padding: 0;

    list-style: none;

    @include mobile {
        flex-direction: column;
    }

    @include notMobile {
        &--item {
            &:not(:last-child)::after {
                content: '|';
                margin-right: 4px;
                display: inline-block;
            }
        }
    }

    &--kind {
        font-weight: bold;
    }
}

.resume-location {}

.resume-contact-info {
    font-weight: bold;
}

.resume-section-name {
    margin-top: 40px;

    color: $accentColor;
    font: bold 12px $sansSerifFont;
    text-transform: uppercase;
}

.resume-entry {
    break-inside: avoid;

    &--title {
        margin: 22px 0 0;
        font-weight: normal;
        font-size: 16px;
        break-inside: avoid;

        .resume--side & {
            font-size: 12px;
        }
    }

    &--time {
        margin-top: 4px !important;
        font: 11px $sansSerifFont;
        text-transform: uppercase;
    }

    ul, p {
        margin: 8px 0;
    }

    ul {
        padding-left: 16px;
    }
}
