.cta {
    margin: 8.75rem 0; // 140px 0
    text-align: center;

    @include media-breakpoint-down('xs') {
        display: flex;
        flex-direction: column;
        align-items: center;

        margin: 5rem 0; // 80px 0
    }

    &__spacer {
        display: inline-block;
        margin: 1.875rem; // 0 30px
    }
}
