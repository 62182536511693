.tabs {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 2.5rem; // 0 0 40px
    padding: 0;

    list-style: none;

    @include media-breakpoint-down('sm') {
        margin-bottom: 1.5rem; // 24px
    }

    &--full-width {
        &::after {
            content: "";
            flex: 1;
            border-bottom: 2px solid $blue-muted;
        }
    }

    &--centered {
        justify-content: center;
    }

    &__item {
        //
    }

    &__link {
        display: block;

        width: 8.75rem; // 140px
        padding: 1rem 2rem; // 16px 32px
        border-bottom: 2px solid $blue-muted;

        font-weight: 500;
        text-align: center;
        
        transition: $btn-transition;

        @include media-breakpoint-down('lg') {
            width: 6.875rem; // 110px
        }

        @include media-breakpoint-down('md') {
            width: initial;
        }

        @include media-breakpoint-down('sm') {
            padding: 1rem; // 16px
        }

        &:hover {
            text-decoration: none;
        }

        &--active {
            border-bottom: 2px solid $blue;

            &:hover {
                color: $blue;
            }
        }
    }
}
