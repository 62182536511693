.header {
    @extend .container;

    padding-top: 3.25rem; // 52px
    padding-bottom: 3.25rem; // 52px

    &__list {
        display: flex;
        align-items: center;
        
        margin: 0;
        padding: 0;
        list-style: none;

        @include media-breakpoint-down('sm') {
            flex-wrap: wrap;
            justify-content: space-around;
        }
    }

    &__item {
        text-align: center;

        @include media-breakpoint-up('md') {
            flex: 1;
        }

        &--logo {
            flex: auto;

            @include media-breakpoint-down('sm') {
                flex: 100%;
                order: -1;

                margin-bottom: 2.5rem; // 40px
            }
        }
    }

    &__link {
        font-weight: 500;

        &:hover {
            text-decoration: none;
        }

        &--logo, &--logo:hover {
            color: $body-color;
        }
    }

    &__logo {
        display: inline-block;
        margin-bottom: 0;

        font-size: 3rem;
        font-weight: 300;
    }
}
