.page-title {
    margin: 3.75rem 0 8.75rem; // 60px 140px

    font-size: 4rem; // 64px
    font-weight: bold;

    text-align: center;

    &--big {
        margin: 8.75rem 0 16.25rem; // 140px 260px

        font-size: 4.5rem; // 72px
        font-weight: 900;
    }

    @include media-breakpoint-down('xs') {
        &, &--big {
            margin: 3.75rem 0 8.75rem; // 60px 140px
            font-size: 3rem; // 48px
        }
    }

    &__subtitle {
        display: block;

        font-size: 2.25rem; // 36px
        font-weight: normal;
    }
}
